@import '~@shopify/polaris-tokens/dist/index';

.Checkmark-Container {
  position: relative;
  width: 0px;
  height: 0px;
  top: 13px;
  right: 20px;

  .Polaris-Icon {
    position: absolute;
    width: 35px !important;
    height: 35px !important;
    max-width: inherit !important;
    max-height: inherit !important;
  }
}

.Ultimate-Plan-Card {
  width: 260px;
  margin: 30px 30px 20px 0;
  color: $color-blue-dark;
  border: 3px solid $color-blue-dark;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  vertical-align: top;

  .Polaris-Card {
    background-color: $color-sky-lighter;
    margin: -1px;
    border-radius: 3px;
    overflow: hidden;
    .Plan-Header-Wrapper {
      padding: 15px;
      border-bottom: 1px solid $color-blue-dark;
      background-color: $color-blue-light;

      .Plan-Name {
        font-size: 1.25rem;
        margin: 3px 0;
        p {
          font-weight: bold;
        };
        .basic-package {
          margin: 24px 0 3px;
        }
      }

      .Monthly-Cost {
        font-size: 2.625rem;
        line-height: 2.625rem;
        font-weight: 600;
        font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
        height: auto;
        letter-spacing: -2px;
        span {
          text-indent: -5px;
          display: block;
        }
      }

      .Monthly-Subtitle {
        font-weight: 400;
        font-size: .8125rem;
        line-height: 1.25rem;
        margin: 2px 0 3px;
        letter-spacing: 0;
      }
    }
  }

  .Plan-Activation-Section {
    margin-bottom: 20px;
    .Scopes-Activation-Links {
      .Strike-Out {
        text-decoration: line-through;
      }
      .Polaris-Button {
        margin-bottom: 10px;
      }
    }
    .Description-Text, .Activation-Instructions{
      font-size: .9375rem;
      color: $color-blue-dark;
      width: 175px;
      margin: 20px auto 20px;
    }
    .Activation-Instructions {
      height: 68px;
      padding-top: 20px;
    }
    .Plan-Activated {
      font-weight: 700;
      margin-top: 27px;
      text-transform: uppercase;
    }
    .ActivateButtonLink {
      color: $color-white;
      text-decoration: none;
    }
    .Polaris-Link {
      display: block;
      margin-top: 5px;
      text-decoration: underline;
    }
  }

  .Plan-Card-Tier{
    color: $color-white;
    background-color: $color-blue-dark;
    font-weight: 400;
    font-size: .8125rem;
    min-height: 2.5rem;
    padding: 0.625rem 0;
    border-top: 1px solid rgb(249 250 251 / 50%);
  }
}

@media(max-width: 500px){
  .Ultimate-Plan-Card{
    margin: 20px auto;
  }
}
