.TaxPercent .Polaris-TextField {
  max-width: 100px;
}

.css-customization-section .Polaris-FormLayout__Item  {
  flex-grow: 0;
  flex-basis: 29% !important;
}

@media only screen and (max-width: 550px) {
  .css-customization-section .Polaris-FormLayout__Item  {
    flex-basis: 100% !important;
  }
}

.advanced-liquid-links {
  margin: 0 0 20px 20px;
}

