@import "~@shopify/polaris-tokens/dist/index";

.Polaris-Tooltip__Content {
  max-width: 250px !important;
}

.InventoryPicker-Row {
  margin-bottom: 1.125rem;
}

.InventoryPicker-Row + .InventoryPicker-Row {
  border-top: 1px solid #dfe3e8;
  padding-top: 1.25rem;
}

.InventoryPicker-Row .Polaris-TextField {
  max-width: 130px;
}

/*
Fixes weird background flashing in Safari caused by quickly incrementing/
decrementing Polaris TextFields of type=number
*/
.Polaris-Stack__Item .Polaris-Button {
  -webkit-user-select: text;
}

.InventoryPicker-Fields {
  margin-bottom: 0.75rem;
  .InventoryPicker-Button {
    margin-right: 10px;
  }

  .FlexRow {
    display: flex;
    flex-direction: row;
  }

  .Quantity-Extras {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .ThreeButtons {
    margin-top: 12px;
    span {
      outline: none;
    }
    .Polaris-Icon {
      fill: $color-sky-dark;
      outline: none;
    }
  }

  @media (max-width: 600px) {
    .InventoryPicker-Button {
      margin-right: 5px;
    }

    .Polaris-Icon {
      height: 35px;
      width: 35px;
      margin-top: 6px;
    }

    .Quantity-Extras {
      .Polaris-Icon {
        margin: 0;
        height: 20px;
      }
    }
  }

  @media (max-width: 350px) {
    .ThreeButtons {
      span {
        order: -1;
      }
      .Polaris-Icon {
        margin-top: -10px;
        margin-bottom: 6px;
      }
      .Polaris-Button {
        margin-bottom: 10px;
        width: 100%;
      }
    }
    .ThreeButtons.FlexRow {
      flex-direction: column;
    }
  }
}
