/* 
"display:flex" fixes weird background flashing in Safari caused by quickly
incrementing/decrementing Polaris TextFields of type=number
*/
.OfferSummarySection {
  margin-top: 20px;
  display: flex;
}

.OfferSummarySection .Polaris-Card {
  flex-grow: 1;
}
