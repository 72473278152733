.OffersListEmpty__Wrapper {
  margin-top: 1.25rem;
  border-top: 1px solid #dfe3e8;
}

.OffersListEmpty__Content {
  height: 300px;
  max-width: 500px;
  margin: auto;
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
