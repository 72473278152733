.AccordionItem__row {
  border-top: 1px solid #dfe3e8;
}

.AccordionItem__heading {
  border: none;
  background: transparent;
  width: 100%;
  font-size: inherit;
  text-align: left;
  padding: 20px;
}

.AccordionItem__heading:active {
  color: inherit;
}

.AccordionItem__content {
  padding: 0 20px 20px;
  max-width: 720px;
}

.AccordionItem__content .Polaris-TextStyle--variationCode {
  font-size: 1em;
}
