@import "~@shopify/polaris-tokens/dist/index";
@import "./offer-list-variables";

.OffersList__Header {
  margin: 1rem -1rem -1rem;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid $color-sky;

  @media (max-width: $double-row-breakpoint - 1) {
    .OffersList__Column:not(.OffersList__Column--main) {
      display: none;
    }
  }
}
