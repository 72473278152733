@import "~@shopify/polaris-tokens/dist/index";
@import "./offer-list-variables";


.OffersList__Row {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  margin: 0 -10px;

  &--disabled {
    color: $color-ink-lighter;
  }

  @media (max-width: $double-row-breakpoint - 1) {
    flex-wrap: wrap;
  }
}

.OffersList__Column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  margin: 0 10px;
}

.OffersList__Column--main {
  flex-shrink: 1;
  flex-grow: 1;
  margin-bottom: 0.5rem;
  min-width: 0;
  padding-right: $mobile-actions-col-width + 0.75rem;

  @media (min-width: $collapsed-actions-breakpoint) {
    padding-right: $desktop-actions-col-width + 0.75rem;
  }

  @media (max-width: $double-row-breakpoint - 1) {
    width: 100%;
  }

  @media (min-width: $double-row-breakpoint) {
    padding-right: 0;
    margin-bottom: 0;
  }
}

.OffersList__Column--status {
  align-items: flex-start;
  width: 4.6875rem;
}

.OffersList__Column--reporting {
  @media (max-width: $double-row-breakpoint - 1) {
    flex-direction: row;
    color: $color-ink-lighter;
    margin: 0;

    & + &:before {
      display: flex;
      flex-direction: column;
      content: "•";
      margin: 0 0.3125rem;
    }
  }

  @media (min-width: $double-row-breakpoint) {
    white-space: nowrap;
    width: 3.4375rem;
  }
}

.ReportingColumn__InlineLabel {
  @media (min-width: $double-row-breakpoint) {
    display: none;
  }
}

.OffersList__Column--actions {
  color: $color-ink-lightest;
  width: $mobile-actions-col-width;
  margin-right: $spacing-tight;

  @media (min-width: $collapsed-actions-breakpoint) {
    width: $desktop-actions-col-width;
  }

  @media (max-width: $double-row-breakpoint - 1) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.Deactivated {
  background: $color-sky-light;
  .Polaris-ResourceItem {
    cursor: default;
  }
}
