.WelcomePage__IframeWrap {
  position: relative;
  padding-bottom: 56%; // match aspect ratio of embed
  margin: 2rem 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
  }
}


.WelcomePage .Polaris-EmptyState__Details {
  width: 100% !important;
}

