.InlineColorPicker__control {
  position: relative;
}

.InlineColorPicker__swatch {
  position: absolute;
  width: 22px;
  height: 22px;
  border: 0.5px solid #c4cdd5;
  border-radius: 3px;
  bottom: 7px;
  right: 7px;
  z-index: 10;
  box-shadow: 0 0 0.5px 1px rgba(0, 0, 0, 0.1) inset;
}
