.AppearancePreview {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SalesBadgesSection .Polaris-Card__Section {
  width: 100%;
}

/* ImagePreview moves to left on higher res display */
@media screen and (min-width: 875px) {
  .AppearancePreview {
    flex-direction: row;
  }

  .AppearancePreview .ImagePreview {
    flex-shrink: 0;
    flex-basis: calc(200% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.AppearancePreview .Polaris-Caption {
  padding-top: 0.625rem;
}

/* Control Layout of Input Field elements */
.AppearancePreview .InputWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AppearancePreview .InputWrapper .Polaris-Labelled__LabelWrapper {
  /* Label span input and details button to prevent */
  /* having to account for it in the details button positioning */
  width: 100%;
}

.AppearancePreview .InputWrapper .InlineColorPicker__control {
  flex-grow: 1;
  /* Shopify margin/padding from other elements */
  padding-right: 0.25rem;
}

.AppearancePreview .InputWrapper .DetailsButton {
  /* Vertical alignment of the button icon */
  align-self: center;
}

.InputWrapper > div {
  width: 99%;
}

.DetailsButton.ExtraPadding {
  padding-top: 1.56rem;
}

@media (pointer: fine) {
  /* pointer: fine for mouse based devices */
  .AppearancePreview .InputWrapper .DetailsButton {
    display: none;
  }
}

/* Fragile - Adjusts the width of the input field to account for the details
   * button on mobile to prevent wrapping the button to the next line */
@media (pointer: coarse) {
  /* pointer: coarse for touch based devices */
  .AppearancePreview .InputWrapper .InlineColorPicker__control {
    /* full width - shopify padding/margin - button dimensions */
    max-width: calc(100% - 0.25rem - 20px);
  }
}

@media (min-width: 555px) and (max-width: 875px) {
  .AppearancePreview .Polaris-FormLayout--grouped .Polaris-FormLayout__Item {
    /* override polaris setting flex: 1 1 22rem */
    flex-basis: inherit;
    /* override polaris setting 100% - 2rem as a width
       * in this case we want a half a card, but also needs to
       * account for a rem of 4rem over 100% because of the
       * spacing between components*/
    max-width: calc(50% - 1.25rem);
  }
}
