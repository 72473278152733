/* replicating FormLayout.Item flex css */
.ApplicabilityDate__Row .Polaris-Stack__Item {
  flex: 1 1 13.75rem;
  min-width: 13.75rem;
  max-width: calc(100% - 1rem);
}

.ApplicabilityDate__Row .Polaris-Connected__Item {
  flex-basis: 50%;
}

/* override some weird default ChoiceList error styling */
#applicability\.channels\[\] .Polaris-ChoiceList__ChoiceChildren {
  padding-left: 0;
}
