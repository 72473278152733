#expand-container {
  overflow: hidden;
  padding: 5px;
  margin: -5px;
}

#animated-banner {
  margin-top: -100%;
  transition: all 1s;
}

#animated-banner.expanded {
   margin-top: 0;
}
