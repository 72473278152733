@media (min-width: 500px) {
  .MultipleOffers-Choices ul {
    display: flex;
    flex-wrap: wrap;
  }

  .MultipleOffers-Choices li {
    flex: 1 0 33.333%;
  }

  .MultipleOffers-Choices .Polaris-Choice {
    margin-bottom: 0.25rem;
  }
}
