@import "~@shopify/polaris-tokens/dist/index";
@import "../OffersList/offer-list-variables";

.OffersListItem__Availability,
.OffersListItem__Summary {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.OffersListItem__Name {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-extra-tight;
  color: $color-blue;
}

.Deactivated .OffersListItem__Name {
  color: inherit;
}

.OffersListItem__PreviewLink {
  display: flex;
  margin-left: 0.3125rem;

  .Polaris-Link {
    display: flex;
  }

  .Polaris-Icon {
    width: .9375rem;
    height: .9375rem;
  }
}

.OffersListItem__Actions {
  .Polaris-Button {
    display: flex;
  }
}

.OffersListItem {
  .Polaris-ResourceList-Item__Media {
    @media (max-width: $collapsed-actions-breakpoint - 1) {
      display: none;
    }
  }
}

.Polaris-ResourceList__ItemWrapper:focus {
  box-shadow: inset 0.125rem 0 0 $color-indigo;
  background-image: linear-gradient(
    rgba(223, 227, 232, 0.3),
    rgba(223, 227, 232, 0.3)
  );
}
