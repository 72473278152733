.VolumeTier-Row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.VolumeTier-Column {
  flex-grow: 0;
  flex-shrink: 0;
}

/* TODO: responsive breakdown of these rows */

.VolumeTier-Row__Header .VolumeTier-Column__Discount {
  flex-basis: calc(75% + 18px);
}

.VolumeTier-Column__Quantity {
  flex-basis: calc(25% - 36px);
}

.VolumeTier-Column__Discount {
  flex-basis: calc(75% - 36px);
}

.VolumeTier-Column__Action {
  flex-basis: 36px;
}

.VolumeTier-Errors {
  flex-basis: 100%;
  margin-top: 0.375rem;
}
