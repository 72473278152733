/*
For some reason when using a ResourceList, there's a 20px margin on the
top of the page causing a scrollbar on the body
*/
#root {
  margin-top: -20px;
  padding-top: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.Polaris-Card hr {
  color: #dfe3e8;
  border-style: solid;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Polaris-Choice {
  cursor: pointer;
}

#support-widget {
  background-color: #93202b;
  color: white;
  fill: white;
  position: fixed;
  bottom: 1.25rem;
  left: 1.25rem;
  padding: 0.5803 .982rem;
  border-radius: 624rem;
  letter-spacing: 0.6;
  font-size: .937rem;
  font-weight: 700;
  display: flex;
  z-index: 30;
}
#support-widget span {
  padding: 0.3rem;
}
#support-widget span:first-child {
  height: 20px;
  padding-right: 0.3rem;
}

/*
Card and DataTable polaris component has different kind of padding
*/
.usageFeesTable .Polaris-Card__Header{ padding:1rem 1rem 0; }
@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){ .usageFeesTable .Polaris-Card__Header{ padding:1.25rem 1rem 0; } }
@media (min-width: 30.625em){ .usageFeesTable .Polaris-Card__Header{ padding:1.25rem 1rem 0; } }
