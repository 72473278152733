// point at which (scaling down) the offers list item switches to two rows
$double-row-breakpoint: 760px;

// point at which (scaling down) the actions button collapses to icon-only
$collapsed-actions-breakpoint: 400px;

// width of the actions button on tablet
$desktop-actions-col-width: 1.25rem;

// width of the actions button when fully collapsed
$mobile-actions-col-width: 2.5rem;
