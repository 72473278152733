@import "~@shopify/polaris-tokens/dist/index";

.ChartTabs {
  &--loading {
    padding: 1.25rem;
    margin-bottom: 0.0625rem;
    border-bottom: 1px solid $color-sky;
  }

  .Polaris-Tabs__Title {
    padding-bottom: 0.625rem;
  }

  .Polaris-Tabs__Tab--selected .Polaris-DisplayText {
    color: $color-indigo;
  }
}

.ChartTab__Tab {
  min-width: 14rem - 1.6 - 1.6;
  text-align: left;

  &--loading {
    min-height: 2.56rem;
    margin-right: 1rem;
    padding-top: 0.25rem;
  }
}

.ChartTab__Label {
  margin-bottom: 0.25rem;
}

.Chart__LoadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
