$labelFontSize: 0.75rem;

.ReportingChart__Wrapper {
  height: 188px;
  margin: 0 -4px;

  @media (min-width: 700px) {
    height: 238px;
  }

  // consistent axis alignment
  // https://github.com/plouc/nivo/issues/164#issuecomment-413186216
  text {
    alignment-baseline: baseline !important; /* reset since Chrome wrongfully sees it as valid */
    dominant-baseline: middle;
  }
}

.ReportingLineChart--Empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;

  p + p {
    margin-top: 0.625rem;
  }
}

.ChartTooltip__Date {
  font-size: $labelFontSize;
}

.ChartLabel__Item {
  font-size: $labelFontSize;
  display: flex;
  align-items: center;
}

.ChartLabel__Pip {
  display: inline-flex;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-right: 0.3125rem;
}
