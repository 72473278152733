.BadgePreview {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.BadgePreviewContainer {
  position: relative;
  width: 100%;
}

.BadgePreview img {
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  max-width: 100%;
  width: 100%;
  vertical-align: middle; /* CSS reset */
}

.BadgePreviewDisclaimer {
  padding-top: 8px;
}

/* ch128952 Fix for Safari Bug */
@supports ((min-width: -webkit-fit-content) or (min-width: -moz-fit-content) or (min-width: fit-content)) {
  @media (min-width: 390px) {
    .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
      min-width: auto !important;
    }
  }
}