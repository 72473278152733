@import '~@shopify/polaris-tokens/dist/index';

.Billing-Table{
  .Polaris-Card{
    max-width: 460px;
    pointer-events: none;
    margin: 20px 0;
  }

  tbody tr:nth-child(1) th {
    background-color: $color-blue-light;
    font-weight: 600;
    color: $color-blue-dark;
    font-size: 1.125rem;
  }

  th.Polaris-DataTable__Cell--firstColumn {
    background-color: $color-blue-dark;
    color: $color-white;
    height: 0.625rem;
    padding: .8125rem;
  }

  th.Polaris-DataTable__Cell--firstColumn.Polaris-DataTable__Cell--header {
    background-color: $color-white;
    color: $color-blue-dark;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .Polaris-DataTable__Cell--total {
    background-color: $color-white !important;
    color: $color-black !important;
  }

  tr{
    line-height: 5px;
  }

  .Current-Billing-Details {
    margin-left: 10px;
    font-weight: 600;
  }
}
