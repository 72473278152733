@import "~@shopify/polaris-tokens/dist/index";

.VerboseOfferTypeWrapper {
  background-color: $color-sky-light;
}

.VerboseOfferTypeSection {
  margin-bottom: 20px;
  .Polaris-Choice {
    padding: 0.875rem 1.25rem;
    width: 100%;
  }

  .Polaris-Choice__Control {
    display: none;
  }

  .Polaris-Choice__Label {
    width: 100%;
  }

  .Polaris-Icon {
    width: 16px;
    height: 16px;
  }
}

.VerboseOfferType__Example {
  display: block;
  margin-top: 0.5rem;
}

.VerboseOfferType__Quote {
  display: inline-block;
  padding: 0.125rem 0.625rem;
  border-radius: 2px;
  border: 1px dashed #2858D1;
  color: #2858D1;
}

.VerboseOfferType__Tag {
  margin-top: 0.5rem;
  color: #5E6C7B;
  font-style: italic;
}

.VerboseOfferTypeCard {
  cursor: pointer;
  padding: 1.4em 1.25rem;

  &:not(:first-child) {
    border-top: solid 1px $color-sky;
  }

  &:not(.VerboseOfferCard--Checked):hover {
    background-color: $color-sky-lighter;
  }
}

.VerboseOfferCard--Checked {
  cursor: default;

  .Polaris-Choice {
    cursor: default;
  }
}

.VerboseOfferCard--Deactivated {
  opacity: 0.3;
  pointer-events: none;
}

.LockIcon-Position{
  position: relative;
  width: 0;
  height: 0;
  left: 96%;
  top: -7px;
  .LockIcon {
    width: 25px;
    height: 25px;
    .Polaris-Icon {
      width: 25px;
      height: 25px;
      position: relative;
    }
  }
}

@media(max-width: 500px){
  .LockIcon-Position{
    left: calc(100% - 33px);
  }
}

.Offer-Section-Layout-Description{
  margin-bottom: 20px;
}
